/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import moment from "moment";

export default {
  date: (value: Date | string, format: string) => {
    if (value) {
      if (format) {
        return moment(value).format(format);
      } else {
        return moment(value).format("DD/MM/YYYY");
      }
    }
    return "";
  },
  numberFormat: (value: number = 0): string => {
    return new Intl.NumberFormat().format(value);
  },
  enum: (value: string, enums: any) => {
    if (value) {
      return enums[parseInt(value, 10)];
    }
  },
  byteToSize: (value: number) => {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (value === 0) {
      return "0 Byte";
    }
    const i: number = Math.floor(Math.log(value) / Math.log(1024));
    return Math.round(value / Math.pow(1024, i)) + " " + sizes[i];
  },
  encodeHtmlEntities: (str: string) => {
    if (str) {
      return String(str)
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;");
    }
    return "";
  },
  decodeHtmlEntities: (str: string) => {
    if (str) {
      return String(str)
        .replace(/&amp;/g, "&")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"');
    }
    return "";
  },
  subpath: (value: string) => {
    if (process.env.NODE_ENV === "production") {
      return `${process.env.BASE_URL}${value}`;
    }
    return `/${value}`;
  },
  label: (value: any, locale: string, label = "label") => {
    if (locale === "id") {
      return value["translation"];
    }
    return value[label];
  },
  time: (value: string) => {
    if (value) {
      const splitTime = value.split(":");
      const result = splitTime[0] + ":" + splitTime[1];

      const date = new Date();
      date.setHours(parseInt(splitTime[0]), parseInt(splitTime[1]));
      const dateEnd = new Date();
      dateEnd.setHours(date.getHours() + 1, date.getMinutes() + 30);
      const hourEnd =
        dateEnd.getHours() < 10 ? `0${dateEnd.getHours()}` : dateEnd.getHours();
      const minEnd =
        dateEnd.getMinutes() < 10
          ? `0${dateEnd.getMinutes()}`
          : dateEnd.getMinutes();

      const resultEnd = `${hourEnd}:${minEnd}`;
      return result.toString() + " - " + resultEnd.toString();
    }
    return "";
  },
};
