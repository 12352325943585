import { SUCCESS_STATUS_CODE } from "@/constants/response.constant";
import toast from "./toast.function";

function capitalize(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

export function errorResponseHandler(error: any) {
  const BreakException = {};

  if (
    // eslint-disable-next-line no-prototype-builtins
    error.config.hasOwnProperty("errorHandle") &&
    error.config.errorHandle === false
  ) {
    return Promise.reject(error);
  }

  if (error.response) {
    const response = error.response;

    if (response.status === 401) return;

    if (response.status === 404) {
      toast.error("Request server not found");
      return;
    }

    if (response.status === 500) {
      toast.error("Internal server error");
      return;
    }

    if (response?.data?.message) {
      toast.error(response.data.message);
      return;
    }

    if (Array.isArray(response.data)) {
      try {
        Array.from(response.data).forEach((item) => {
          if (item === "Signature has expired.") {
            toast.error("Session has expired.");
            throw BreakException;
          }
          toast.error(item);
        });
      } catch (e) {
        // When you need more debugging you can print exception
      }
      return;
    }

    for (const key in response.data) {
      if (key) {
        if (typeof response.data[key] === "string") {
          const keyField = capitalize(key.replace("_", " "));
          if (response.data[key] === "Signature has expired.") {
            toast.error("Session has expired.");
            break;
          }
          toast.error(`Field ${keyField}, ${response.data[key]}`);
          continue;
        }

        if (Array.isArray(error.response.data[key])) {
          try {
            Array.from(response.data[key]).forEach((item) => {
              if (item === "Signature has expired.") {
                toast.error("Session has expired.");
                throw BreakException;
              }
              const keyField = capitalize(key.replace("_", " "));
              toast.error(`Field ${keyField}, ${item}`);
            });
          } catch (e) {
            // When you need more debugging you can print exception
          }
        }
      }
    }
  }
}

export function responseHandler(response: any) {
  if (SUCCESS_STATUS_CODE.includes(response.status)) {
    return response;
  }
  return null;
}

export function requestHandler(config: any) {
  return config;
}
