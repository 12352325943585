












































































































































































































































































































































































































































































































































































































import Vue from "vue";
import $ from "jquery";
import Component from "vue-class-component";
import {
  CANDIDAT_SIGNIN,
  SCHOOL_SIGNUP,
  COMPANY_SIGNUP,
} from "@/constants/portal.constant";
import { Mutation, State } from "vuex-class";
import FlagIcon from "@/components/icons/FlagIcon.vue";

@Component({
  components: { FlagIcon },
})
export default class AppNavbar extends Vue {
  @State("locale")
  private locale!: string;

  @Mutation("mutationLocale") private mutationLocale: any;

  private navbarToggle() {
    $("#main-nav").toggleClass("navbar-expanded");
    $("#nav-mobile").toggleClass("show");
  }

  private redirectTo(portal = "candidate") {
    switch (portal) {
      case "company":
        window.open(COMPANY_SIGNUP, "_blank");
        break;
      case "school":
        window.open(SCHOOL_SIGNUP, "_blank");
        break;
      default:
        window.open(CANDIDAT_SIGNIN, "_blank");
        break;
    }
  }
}
