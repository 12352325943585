import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/Styles/app.css";
import "./registerServiceWorker";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import i18n from "./i18n";
import filters from "./filters";
import AOS from "aos";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import InfiniteSlideBar from "vue-infinite-slide-bar";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import AppLayout from "@/layouts/AppLayout.vue";
import VueMeta from "vue-meta";

Vue.component("AppLayout", AppLayout);
Vue.component("InfiniteSlideBar", InfiniteSlideBar);
Vue.component("VueSlickCarousel", VueSlickCarousel);
Vue.component("v-select", vSelect);

AOS.init();

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueMeta);

Vue.config.productionTip = false;

new Vue({
  filters,
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
