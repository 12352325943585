import { render, staticRenderFns } from "./AppFooter.vue?vue&type=template&id=6b2db80e&scoped=true&"
import script from "./AppFooter.vue?vue&type=script&lang=ts&"
export * from "./AppFooter.vue?vue&type=script&lang=ts&"
import style0 from "./AppFooter.vue?vue&type=style&index=0&id=6b2db80e&scoped=true&lang=css&"
import style1 from "./AppFooter.vue?vue&type=style&index=1&module=foot&lang=css&"




function injectStyles (context) {
  
  this["foot"] = (style1.locals || style1)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "6b2db80e",
  null
  
)

export default component.exports