export const FOOTER_PAGES = [
  {
    name: "footer_page_1",
    subpages: [
      {
        name: "footer_page_1_subpage_1",
        route: "tracer-study-1",
        is_url: false,
      },
      {
        name: "footer_page_1_subpage_2",
        route: "tracer-study-2",
        is_url: false,
      },
      {
        name: "footer_page_1_subpage_3",
        route: "tracer-study-3",
        is_url: false,
      },
      {
        name: "footer_page_1_subpage_4",
        route: "tracer-study-4",
        is_url: false,
      },
      {
        name: "footer_page_1_subpage_5",
        route: "tracer-study-5",
        is_url: false,
      },
      {
        name: "footer_page_1_subpage_6",
        route: "tracer-study-6",
        is_url: false,
      },
      {
        name: "footer_page_1_subpage_7",
        route: "tracer-study-7",
        is_url: false,
      },
      {
        name: "footer_page_1_subpage_8",
        route: "tracer-study-8",
        is_url: false,
      },
      {
        name: "footer_page_1_subpage_9",
        route: "tracer-study-9",
        is_url: false,
      },
      {
        name: "footer_page_1_subpage_10",
        route: "tracer-study-10",
        is_url: false,
      },
      {
        name: "footer_page_1_subpage_11",
        route: "tracer-study-11",
        is_url: false,
      },
      {
        name: "footer_page_1_subpage_12",
        route: "tracer-study-12",
        is_url: false,
      },
      {
        name: "footer_page_1_subpage_13",
        route: "tracer-study-13",
        is_url: false,
      },
    ],
  },
  {
    name: "footer_page_2",
    subpages: [
      {
        name: "footer_page_2_subpage_1",
        route: "job-fair-1",
        is_url: false,
      },
      {
        name: "footer_page_2_subpage_2",
        route: "job-fair-2",
        is_url: false,
      },
      {
        name: "footer_page_2_subpage_3",
        route: "job-fair-3",
        is_url: false,
      },
      {
        name: "footer_page_2_subpage_4",
        route: "job-fair-4",
        is_url: false,
      },
      {
        name: "footer_page_2_subpage_5",
        route: "job-fair-5",
        is_url: false,
      },
      {
        name: "footer_page_2_subpage_6",
        route: "job-fair-6",
        is_url: false,
      },
      {
        name: "footer_page_2_subpage_7",
        route: "job-fair-7",
        is_url: false,
      },
      {
        name: "footer_page_2_subpage_8",
        route: "job-fair-8",
        is_url: false,
      },
      {
        name: "footer_page_2_subpage_9",
        route: "job-fair-9",
        is_url: false,
      },
      {
        name: "footer_page_2_subpage_10",
        route: "job-fair-10",
        is_url: false,
      },
      {
        name: "footer_page_2_subpage_11",
        route: "job-fair-11",
        is_url: false,
      },
      {
        name: "footer_page_2_subpage_12",
        route: "job-fair-12",
        is_url: false,
      },
      {
        name: "footer_page_2_subpage_13",
        route: "job-fair-13",
        is_url: false,
      },
    ],
  },
  {
    name: "footer_page_3",
    subpages: [
      {
        name: "footer_page_3_subpage_1",
        route: "career-center-1",
        is_url: false,
      },
      {
        name: "footer_page_3_subpage_2",
        route: "career-center-2",
        is_url: false,
      },
      {
        name: "footer_page_3_subpage_3",
        route: "career-center-3",
        is_url: false,
      },
      {
        name: "footer_page_3_subpage_4",
        route: "career-center-4",
        is_url: false,
      },
      {
        name: "footer_page_3_subpage_5",
        route: "career-center-5",
        is_url: false,
      },
      {
        name: "footer_page_3_subpage_6",
        route: "career-center-6",
        is_url: false,
      },
      {
        name: "footer_page_3_subpage_7",
        route: "career-center-7",
        is_url: false,
      },
      {
        name: "footer_page_3_subpage_8",
        route: "career-center-8",
        is_url: false,
      },
      {
        name: "footer_page_3_subpage_9",
        route: "career-center-9",
        is_url: false,
      },
      {
        name: "footer_page_3_subpage_10",
        route: "career-center-10",
        is_url: false,
      },
      {
        name: "footer_page_3_subpage_11",
        route: "career-center-11",
        is_url: false,
      },
      {
        name: "footer_page_3_subpage_12",
        route: "career-center-12",
        is_url: false,
      },
    ],
  },
  {
    name: "footer_page_4",
    subpages: [
      {
        name: "footer_page_4_subpage_1",
        route: "csr-1",
        is_url: false,
      },
      {
        name: "footer_page_4_subpage_2",
        route: "csr-2",
        is_url: false,
      },
      {
        name: "footer_page_4_subpage_3",
        route: "csr-3",
        is_url: false,
      },
      {
        name: "footer_page_4_subpage_4",
        route: "csr-4",
        is_url: false,
      },
      {
        name: "footer_page_4_subpage_5",
        route: "csr-5",
        is_url: false,
      },
      {
        name: "footer_page_4_subpage_6",
        route: "csr-6",
        is_url: false,
      },
      {
        name: "footer_page_4_subpage_7",
        route: "csr-7",
        is_url: false,
      },
      {
        name: "footer_page_4_subpage_8",
        route: "csr-8",
        is_url: false,
      },
      {
        name: "footer_page_4_subpage_9",
        route: "csr-9",
        is_url: false,
      },
      {
        name: "footer_page_4_subpage_10",
        route: "csr-10",
        is_url: false,
      },
      {
        name: "footer_page_4_subpage_11",
        route: "csr-11",
        is_url: false,
      },
    ],
  },
  {
    name: "footer_page_5",
    subpages: [
      {
        name: "footer_page_5_subpage_1",
        route: "hands-on-1",
        is_url: false,
      },
      {
        name: "footer_page_5_subpage_2",
        route: "hands-on-2",
        is_url: false,
      },
      {
        name: "footer_page_5_subpage_3",
        route: "hands-on-3",
        is_url: false,
      },
      {
        name: "footer_page_5_subpage_4",
        route: "hands-on-4",
        is_url: false,
      },
      {
        name: "footer_page_5_subpage_5",
        route: "hands-on-5",
        is_url: false,
      },
      {
        name: "footer_page_5_subpage_6",
        route: "hands-on-6",
        is_url: false,
      },
      {
        name: "footer_page_5_subpage_7",
        route: "hands-on-7",
        is_url: false,
      },
      {
        name: "footer_page_5_subpage_8",
        route: "hands-on-8",
        is_url: false,
      },
      {
        name: "footer_page_5_subpage_9",
        route: "hands-on-9",
        is_url: false,
      },
      {
        name: "footer_page_5_subpage_10",
        route: "hands-on-10",
        is_url: false,
      },
      {
        name: "footer_page_5_subpage_11",
        route: "hands-on-11",
        is_url: false,
      },
    ],
  },
  {
    name: "footer_page_6",
    subpages: [
      {
        name: "footer_page_6_subpage_1",
        route: "soft-skill-1",
        is_url: false,
      },
      {
        name: "footer_page_6_subpage_2",
        route: "soft-skill-2",
        is_url: false,
      },
      {
        name: "footer_page_6_subpage_3",
        route: "soft-skill-3",
        is_url: false,
      },
    ],
  },
  {
    name: "footer_page_7",
    subpages: [
      {
        name: "footer_page_7_subpage_1",
        route: "entrepreneur-training-1",
        is_url: false,
      },
      {
        name: "footer_page_7_subpage_2",
        route: "entrepreneur-training-2",
        is_url: false,
      },
      {
        name: "footer_page_7_subpage_3",
        route: "entrepreneur-training-3",
        is_url: false,
      },
    ],
  },
];
