
























































































































































































































































































































































































































































































































































































import filters from "@/filters";
import { Component, Vue } from "vue-property-decorator";
import { State } from "vuex-class";
import { FOOTER_PAGES } from "@/constants/pages.constant";
import $ from "jquery";

@Component({
  filters,
  components: {},
})
export default class JobFair extends Vue {
  @State("locale")
  private locale!: string;

  private subpages = FOOTER_PAGES[1].subpages;
  private tabs = [
    {
      id: 1,
      title: "footer_page_2_subpage_3_header_title_1",
      desc: "footer_page_2_subpage_3_header_desc_1",
      image: require("@/assets/Images/job-fair/3.1-header.png"),
    },
    {
      id: 2,
      title: "footer_page_2_subpage_3_header_title_2",
      desc: "footer_page_2_subpage_3_header_desc_2",
      image: require("@/assets/Images/job-fair/3.2-header.png"),
    },
  ];
  private tab = this.tabs[0];

  private get jobFairRight() {
    return require(`@/assets/Images/job-fair/${this.routeId}-right.png`);
  }
  private get page1Items() {
    return Array.from({ length: 5 }, (_, i) => i + 1).map((value) => ({
      id: value,
    }));
  }
  private get page2Items() {
    return Array.from({ length: 3 }, (_, i) => i + 1).map((value) => ({
      id: value,
      image: require(`@/assets/Images/job-fair/2.${value}.png`),
      title: this.$t(`footer_page_2_subpage_2_content_title_${value}`),
      desc: this.$t(`footer_page_2_subpage_2_content_desc_${value}`),
    }));
  }
  private get page3Items() {
    return Array.from({ length: 2 }, (_, i) => i + 1).map((value) => ({
      id: value,
      image: require(`@/assets/Images/job-fair/3.${value}.png`),
      title: this.$t(`footer_page_2_subpage_3_content_title_${value}`),
      desc: this.$t(`footer_page_2_subpage_3_content_desc_${value}`),
    }));
  }
  private get page4Items() {
    return Array.from({ length: 2 }, (_, i) => i + 1).map((value) => ({
      id: value,
      image: require(`@/assets/Images/job-fair/4.${value}.png`),
      title: this.$t(`footer_page_2_subpage_4_content_title_${value}`),
      desc: this.$t(`footer_page_2_subpage_4_content_desc_${value}`),
    }));
  }
  private get page5Items() {
    return Array.from({ length: 3 }, (_, i) => i + 1).map((value) => ({
      id: value,
      image: require(`@/assets/Images/job-fair/5.${value}.png`),
      title: this.$t(`footer_page_2_subpage_5_content_title_${value}`),
      desc: this.$t(`footer_page_2_subpage_5_content_desc_${value}`),
    }));
  }
  private get page6Items() {
    return Array.from({ length: 3 }, (_, i) => i + 1).map((value) => ({
      id: value,
      image: require(`@/assets/Images/job-fair/6.${value}.png`),
      title: this.$t(`footer_page_2_subpage_6_content_title_${value}`),
      desc: this.$t(`footer_page_2_subpage_6_content_desc_${value}`),
    }));
  }
  private get page7Items() {
    return Array.from({ length: 3 }, (_, i) => i + 1).map((value) => ({
      id: value,
      image: require(`@/assets/Images/job-fair/7.${value}.png`),
      title: this.$t(`footer_page_2_subpage_7_content_title_${value}`),
      desc: this.$t(`footer_page_2_subpage_7_content_desc_${value}`),
    }));
  }
  private get page8Items() {
    return Array.from({ length: 3 }, (_, i) => i + 1).map((value) => ({
      id: value,
      image: require(`@/assets/Images/job-fair/8.${value}.png`),
      title: this.$t(`footer_page_2_subpage_8_content_title_${value}`),
      desc: this.$t(`footer_page_2_subpage_8_content_desc_${value}`),
    }));
  }
  private get page9Items() {
    return Array.from({ length: 3 }, (_, i) => i + 1).map((value) => ({
      id: value,
      image: require(`@/assets/Images/job-fair/9.${value}.png`),
      title: this.$t(`footer_page_2_subpage_9_content_title_${value}`),
      desc: this.$t(`footer_page_2_subpage_9_content_desc_${value}`),
    }));
  }
  private get page10Items() {
    return Array.from({ length: 3 }, (_, i) => i + 1).map((value) => ({
      id: value,
      image: require(`@/assets/Images/job-fair/10.${value}.png`),
      title: this.$t(`footer_page_2_subpage_10_content_title_${value}`),
      desc: this.$t(`footer_page_2_subpage_10_content_desc_${value}`),
    }));
  }
  private get page11Items() {
    return Array.from({ length: 3 }, (_, i) => i + 1).map((value) => ({
      id: value,
      image: require(`@/assets/Images/job-fair/11.${value}.png`),
      title: this.$t(`footer_page_2_subpage_11_content_title_${value}`),
      desc: this.$t(`footer_page_2_subpage_11_content_desc_${value}`),
    }));
  }
  private get page12Items() {
    return Array.from({ length: 3 }, (_, i) => i + 1).map((value) => ({
      id: value,
      image: require(`@/assets/Images/job-fair/12.${value}.png`),
      title: this.$t(`footer_page_2_subpage_12_content_title_${value}`),
      desc: this.$t(`footer_page_2_subpage_12_content_desc_${value}`),
    }));
  }
  private get page13Items() {
    return Array.from({ length: 3 }, (_, i) => i + 1).map((value) => ({
      id: value,
      image: require(`@/assets/Images/job-fair/13.${value}.png`),
      title: this.$t(`footer_page_2_subpage_13_content_title_${value}`),
      desc: this.$t(`footer_page_2_subpage_13_content_desc_${value}`),
    }));
  }

  private get contents() {
    switch (this.routeId) {
      case 1:
        return this.page1Items;
      case 2:
        return this.page2Items;
      case 3:
        return this.page3Items;
      case 4:
        return this.page4Items;
      case 5:
        return this.page5Items;
      case 6:
        return this.page6Items;
      case 7:
        return this.page7Items;
      case 8:
        return this.page8Items;
      case 9:
        return this.page9Items;
      case 10:
        return this.page10Items;
      case 11:
        return this.page11Items;
      case 12:
        return this.page12Items;
      case 13:
        return this.page13Items;
      default:
        return [];
    }
  }

  private get routeId() {
    return parseInt(String(this.$route.name).replaceAll("job-fair-", ""));
  }

  private scrollToTop() {
    this.scrollTo(".MainLanding");
  }

  private scrollTo(element: string) {
    $("html, body").animate(
      {
        scrollTop: ($(element) as any).offset().top - 90,
      },
      500
    );
  }
}
