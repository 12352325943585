























































































































































import filters from "@/filters";
import { Component, Vue } from "vue-property-decorator";
import { State } from "vuex-class";
import $ from "jquery";

@Component({
  filters,
  components: {},
  metaInfo: {
    meta: [
      {
        name: "title",
        content:
          "Kuasai Soft Skill, Taklukkan Wawancara, berkembang di tempat kerja! Pelatihan Efektif untuk Kesuksesan Anda.",
      },
      {
        name: "description",
        content:
          "Investasi terbaik yang dapat Anda lakukan adalah pada diri Anda sendiri. Dari penguasaan soft skills hingga persiapan wawancara dan lainnya, kami memiliki pelatihan untuk kesuksesan karir anda.",
      },
      {
        name: "keywords",
        content:
          "Pelatihan Soft Skill, Pelatihan Persiapan Rekrutmen, Pelatihan Pertumbuhan Diri, Pelatihan Pengembangan Keterampilan, Pemberdayaan Diri",
      },
    ],
  },
})
export default class SkillDevelopment extends Vue {
  @State("locale")
  private locale!: string;

  private get items() {
    return Array.from({ length: 3 }, (_, i) => i + 1).map((value) => ({
      id: value,
      image: require(`@/assets/Images/skill-development-item-${value}.svg`),
    }));
  }

  private scrollDown() {
    $("html, body").animate(
      {
        scrollTop: ($("#scroll-down") as any).offset().top - 90,
      },
      500
    );
  }
}
