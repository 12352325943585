import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Landing from "@/views/Landing.vue";
import Academia from "@/views/Academia.vue";
import SkillDevelopment from "@/views/SkillDevelopment.vue";
import EntrepreneurshipIncubation from "@/views/EntrepreneurshipIncubation.vue";
import Events from "@/views/Events.vue";
import Tac from "@/views/Tac.vue";
import TracerStudy from "@/views/TracerStudy.vue";
import JobFair from "@/views/JobFair.vue";
import CareerCenter from "@/views/CareerCenter.vue";
import Csr from "@/views/Csr.vue";
import HandsOn from "@/views/HandsOn.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "landing",
    component: Landing,
  },
  {
    path: "/academia",
    name: "academia",
    component: Academia,
  },
  {
    path: "/skill-development",
    name: "skill-development",
    component: SkillDevelopment,
  },
  {
    path: "/entrepreneurship-incubation",
    name: "entrepreneurship-incubation",
    component: EntrepreneurshipIncubation,
  },
  {
    path: "/events",
    name: "events",
    component: Events,
  },
  {
    path: "/tac",
    name: "tac",
    component: Tac,
  },
  ...Array.from({ length: 13 }, (_, i) => i + 1).map((value) => ({
    path: `/tracer-study/${value}`,
    name: `tracer-study-${value}`,
    component: TracerStudy,
  })),
  ...Array.from({ length: 13 }, (_, i) => i + 1).map((value) => ({
    path: `/job-fair/${value}`,
    name: `job-fair-${value}`,
    component: JobFair,
  })),
  ...Array.from({ length: 12 }, (_, i) => i + 1).map((value) => ({
    path: `/career-center/${value}`,
    name: `career-center-${value}`,
    component: CareerCenter,
  })),
  ...Array.from({ length: 11 }, (_, i) => i + 1).map((value) => ({
    path: `/csr/${value}`,
    name: `csr-${value}`,
    component: Csr,
  })),
  ...Array.from({ length: 11 }, (_, i) => i + 1).map((value) => ({
    path: `/hands-on/${value}`,
    name: `hands-on-${value}`,
    component: HandsOn,
  })),
  ...Array.from({ length: 3 }, (_, i) => i + 1).map((tab) => ({
    path: `/soft-skill/${tab}`,
    name: `soft-skill-${tab}`,
    component: () => import("@/views/SoftSkills.vue"),
  })),
  ...Array.from({ length: 3 }, (_, i) => i + 1).map((tab) => ({
    path: `/entrepreneur-training/${tab}`,
    name: `entrepreneur-training-${tab}`,
    component: () => import("@/views/EntrepreneurTraining.vue"),
  })),
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
  routes,
});

export default router;
