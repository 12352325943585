import axios, { AxiosInstance } from "axios";
import { baseAPI } from "@/functions/apibase.function";
import {
  requestHandler,
  responseHandler,
  errorResponseHandler,
} from "@/functions/axioshandler.function";

const adminInstance: AxiosInstance = axios.create({
  baseURL: `${baseAPI}/admin/api/v1`,
});
adminInstance.interceptors.request.use(requestHandler);
adminInstance.interceptors.response.use(responseHandler, errorResponseHandler);

const schoolInstance: AxiosInstance = axios.create({
  baseURL: `${baseAPI}/school/api/v1`,
});
schoolInstance.interceptors.request.use(requestHandler);
schoolInstance.interceptors.response.use(responseHandler, errorResponseHandler);

const candidateInstance: AxiosInstance = axios.create({
  baseURL: `${baseAPI}/candidate/api/v1`,
});
candidateInstance.interceptors.request.use(requestHandler);
candidateInstance.interceptors.response.use(
  responseHandler,
  errorResponseHandler
);

const companyInstance: AxiosInstance = axios.create({
  baseURL: `${baseAPI}/company/api/v1`,
});
companyInstance.interceptors.request.use(requestHandler);
companyInstance.interceptors.response.use(
  responseHandler,
  errorResponseHandler
);

export function BaseAdmin(): AxiosInstance {
  return adminInstance;
}
export function BaseSchool(): AxiosInstance {
  return schoolInstance;
}
export function BaseCandidate(): AxiosInstance {
  return candidateInstance;
}
export function BaseCompany(): AxiosInstance {
  return companyInstance;
}
