























































































































































import filters from "@/filters";
import { Component, Vue } from "vue-property-decorator";
import { State } from "vuex-class";
import $ from "jquery";

@Component({
  filters,
  components: {},
  metaInfo: {
    meta: [
      {
        name: "title",
        content:
          "Berinovasi, Berkolaborasi, Berkembang: Mewujudkan potensi melalui teknologi terdepan, dukungan inkubasi & kemitraan strategis.",
      },
      {
        name: "description",
        content:
          "Menjembatani pendidikan & industri: skill nyata, kolaborasi transformatif, dan bimbingan wirausaha.",
      },
      {
        name: "keywords",
        content:
          "Portal Karir Online, Aplikasi Seluler, Sistem Informasi Karir, Pengembangan Diri, Keterampilan Lunak, Pelatihan Kewirausahaan, Kolaborasi Antara Industri dan Akademisi, Peluang Jaringan, Konsultasi Karir, Program CSR",
      },
    ],
  },
})
export default class Academia extends Vue {
  @State("locale")
  private locale!: string;

  private get items() {
    return Array.from({ length: 4 }, (_, i) => i + 1).map((value) => ({
      id: value,
      image: require(`@/assets/Images/academia-item-${value}.svg`),
    }));
  }

  private scrollDown() {
    $("html, body").animate(
      {
        scrollTop: ($("#scroll-down") as any).offset().top - 90,
      },
      500
    );
  }
}
