






























































































































































































































import filters from "@/filters";
import { Component, Vue } from "vue-property-decorator";

@Component({
  filters,
  components: {},
})
export default class Tac extends Vue {}
