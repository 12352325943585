




import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class FlagIcon extends Vue {
  /* VUEX */

  /* PROPS */
  @Prop() private name!: string;

  constructor() {
    super();
  }
}
