export const CANDIDAT_SIGNUP = "https://career.support/candidate/#/signin";
export const CANDIDAT_SIGNIN = "https://career.support/candidate/#/signin";

export const SCHOOL_SIGNUP =
  "https://school.career.support/#/registration-typeform";
export const SCHOOL_SIGNIN = "https://school.career.support/#/signin";

export const COMPANY_SIGNUP = "https://company.career.support/signup";
export const COMPANY_SIGNIN = "https://company.career.support/";

export const WHATSAPP_NUMBER = "+6281290328046";
