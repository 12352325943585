

























































































































































































































































































































































































































import $ from "jquery";
import filters from "@/filters";
import { Component, Vue } from "vue-property-decorator";
import { State } from "vuex-class";

@Component({
  filters,
  components: {},
  metaInfo: {
    meta: [
      {
        name: "title",
        content:
          "Career.Support: Solusi Lengkap Pembinaan Karir Siswa- Job Fair, Sistem Pusat Karir Sekolah & CSR Perusahaan.",
      },
      {
        name: "description",
        content:
          "Siap kerja, bukan sekadar lulus! Career.Support ciptakan ekosistem karier terbaik: lengkapi sekolah dengan pusat karier modern, job fair & program CSR perusahaan.",
      },
      {
        name: "keywords",
        content:
          "Pusat Pengembangan Karir, Peluang Kerja, Resume & CV Builder, Konsultasi Karier, Wawancara Kerja, Pasar Karir Terpadu, Keterampilan Kerja, Job fair, Program CSR, Pusat Karir, Ekosistem untuk Pendidikan Kejuruan",
      },
    ],
  },
})
export default class Landing extends Vue {
  @State("locale")
  private locale!: string;

  private get partners() {
    return [
      {
        name: this.isEn ? "Institution<br/>Partners" : "Mitra<br/>Lembaga",
        images: Array.from({ length: 11 }, (_, i) => i + 1).map((value) =>
          require(`@/assets/Images/logo-government-${value}.png`)
        ),
      },
      {
        name: this.isEn ? "School<br/>Partners" : "Mitra<br/>Sekolah",
        images: Array.from({ length: 14 }, (_, i) => i + 1).map((value) =>
          require(`@/assets/Images/logo-school-${value}.png`)
        ),
      },
      {
        name: this.isEn ? "Company<br/>Partners" : "Mitra<br/>Perusahaan",
        images: Array.from({ length: 10 }, (_, i) => i + 1).map((value) =>
          require(`@/assets/Images/logo-company-${value}.png`)
        ),
      },
    ];
  }

  private get academiaItems() {
    return Array.from({ length: 4 }, (_, i) => i + 1).map((value) => ({
      id: value,
      image: require(`@/assets/Images/academia-item-${value}.svg`),
    }));
  }
  private get skillDevelopmentItems() {
    return Array.from({ length: 3 }, (_, i) => i + 1).map((value) => ({
      id: value,
      image: require(`@/assets/Images/skill-development-item-${value}.svg`),
    }));
  }
  private get entrepreneurshipItems() {
    return Array.from({ length: 4 }, (_, i) => i + 1).map((value) => ({
      id: value,
      image: require(`@/assets/Images/entrepreneurship-item-${value}.svg`),
    }));
  }

  private get isEn() {
    return this.locale === "en";
  }

  private scrollDown() {
    // $("html, body").animate(
    //   {
    //     scrollTop: ($("#scroll-down") as any).offset().top - 90,
    //   },
    //   500
    // );
    document
      .getElementById("scroll-down")
      ?.scrollIntoView({ behavior: "smooth" });
  }
}
