




































































































































































































































































































































































import filters from "@/filters";
import { Component, Vue } from "vue-property-decorator";
import { Action, State } from "vuex-class";
import { AxiosResponse } from "axios";

@Component({
  filters,
  components: {},
})
export default class Events extends Vue {
  @Action("getEventLanding", { namespace: "admin" })
  private getEventLanding: any;

  @State("locale")
  private locale!: string;

  private events: Array<any> = [];
  private page = 1;
  private isLastPage = false;
  private isLoading = false;

  private get partners() {
    return [
      ...Array.from({ length: 7 }, (_, i) => i + 1).map((value) =>
        require(`@/assets/Images/landing-instance-${value}.jpg`)
      ),
      ...Array.from({ length: 7 }, (_, i) => i + 1).map((value) =>
        require(`@/assets/Images/landing-school-${value}.jpg`)
      ),
      ...Array.from({ length: 7 }, (_, i) => i + 1).map((value) =>
        require(`@/assets/Images/landing-company-${value}.jpg`)
      ),
    ];
  }

  private get isEn() {
    return this.locale === "en";
  }

  private getData(isReload: boolean = false) {
    if (isReload) {
      this.events = [];
      this.page = 1;
      this.isLastPage = false;
    }
    if (!this.isLoading && !this.isLastPage) {
      this.isLoading = true;
      this.getEventLanding({
        params: {
          page: this.page,
        },
        success: (response: AxiosResponse) => {
          const isLastPage = response.data.data.last_page === this.page;
          this.events = [...this.events, ...response.data.data.data];
          this.page = isLastPage ? this.page : this.page + 1;
          this.isLastPage = isLastPage;
        },
        finally: () => {
          this.isLoading = false;
        },
      });
    }
  }

  private created() {
    this.getData();
  }
}
