












/* eslint-disable @typescript-eslint/no-explicit-any */
import $ from "jquery";
import Swiper from "swiper";
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import AppNavbar from "@/components/AppNavbar.vue";
import AppFooter from "@/components/AppFooter.vue";
import { StripeMenu, StripeMenuPopup } from "@/functions/stripe-menu.function";
import { WHATSAPP_NUMBER } from "@/constants/portal.constant";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as waHover from "@/assets/Images/wa-hover.png";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as waNotHover from "@/assets/Images/wa-not-hover.png";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as waHoverEn from "@/assets/Images/wa-hover-en.png";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as waNotHoverEn from "@/assets/Images/wa-not-hover-en.png";
import { State } from "vuex-class";

@Component({
  components: {
    AppNavbar,
    AppFooter,
  },
})
export default class AppLayout extends Vue {
  @Prop({ default: false })
  private logoColor!: boolean;

  @State("locale")
  private locale!: string;

  @Watch("locale")
  private onChangeLocale() {
    this.waNotHover();
  }

  private whatsapp_number = WHATSAPP_NUMBER;

  private created() {
    $(document).ready(() => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      this.navbarSticky();
      $(window).on("scroll", this.navbarSticky);

      $("a.scrollto").on("click", function (event: any) {
        event.preventDefault();

        const scrollAnimationTime = 1200;
        const target = (this as any).hash;

        $("html, body")
          .stop()
          .animate(
            {
              scrollTop: (($(target).offset() as any).top as number) - 45,
            },
            scrollAnimationTime,
            "easeInOutExpo",
            () => {
              window.location.hash = target;
            }
          );
      });

      $(".swiper-container").each(function () {
        const $this = $(this);
        const boolData = {
          breakpoints: $this.data("sw-breakpoints"),
          active_selector: $this.data("sw-active-selector"),
          cover_flow: $this.data("sw-coverflow"),
          auto_play: $this.data("sw-autoplay"),
          loop: $this.data("sw-loop"),
          centered: $this.data("sw-centered-slides"),
          pagination: $this.data("sw-pagination"),
          nav_arrows: $this.data("sw-nav-arrows"),
        };

        const breakPoints = boolData.breakpoints || false;
        const auto_play =
          boolData.auto_play !== undefined ? boolData.auto_play : false;
        const speed = $this.data("sw-speed") || 1100;
        const effect = $this.data("sw-effect") || "slide";
        const showItems = $this.data("sw-show-items") || 1;
        const loop = boolData.loop !== undefined ? boolData.loop : true;
        const centered =
          boolData.centered !== undefined ? boolData.centered : true;
        const spaceBetween =
          $this.data("sw-space-between") || (showItems > 1 ? 20 : 0);
        const scrollItems = $this.data("sw-scroll-items") || 1;
        const navigationElement = $this.data("sw-navigation");
        const navigationActiveClass =
          $this.data("sw-navigation-active") || "active";
        const navigationActiveSelector =
          boolData.active_selector !== undefined
            ? boolData.active_selector
            : false;
        const paginationCss =
          boolData.pagination !== undefined
            ? boolData.pagination
            : ".swiper-pagination";
        const navigationCss =
          boolData.nav_arrows !== undefined
            ? boolData.nav_arrows
            : ".swiper-button";

        const coverflow = boolData.cover_flow
          ? {
              coverflowEffect: $.extend(
                {
                  stretch: 0,
                  depth: 0,
                  modifier: 1,
                  rotate: 0,
                  slideShadows: false,
                },
                boolData.cover_flow
              ),
            }
          : {};

        const autoplay = auto_play
          ? {
              autoplay: {
                delay: auto_play,
                disableOnIteration: false,
              },
              speed,
            }
          : {};

        const pagination: any = {};

        if (paginationCss) {
          pagination.pagination = {
            el: paginationCss,
            clickable: true,
            dynamicBullets: true,
          };
        }

        if (navigationCss) {
          pagination.navigation = {
            nextEl: navigationCss + "-next",
            prevEl: navigationCss + "-prev",
          };
        }

        let events = {};

        if (navigationElement) {
          events = {
            transitionEnd: () => {
              /* tslint:disable */
              if (!navigationElement) return;
              /* tslint:enable */

              const $navigationElement = $(navigationElement);

              if (navigationActiveSelector) {
                $(
                  navigationActiveSelector + "." + navigationActiveClass,
                  $navigationElement
                ).removeClass(navigationActiveClass);
                $(
                  ".nav-item:eq(" +
                    swiper.realIndex +
                    ") " +
                    navigationActiveSelector,
                  $navigationElement
                ).addClass(navigationActiveClass);
              } else {
                $("." + navigationActiveClass, $navigationElement).removeClass(
                  navigationActiveClass
                );
                $(
                  ".nav-item:eq(" + swiper.realIndex + ")",
                  $navigationElement
                ).addClass(navigationActiveClass);
              }
            },
          };
        }

        const options = $.extend(
          {
            loop,
            slidesPerGroup: scrollItems,
            spaceBetween,
            centeredSlides: centered,
            breakpoints: breakPoints,
            slidesPerView: showItems,
            parallax: true,
            effect,
          },
          pagination,
          autoplay,
          coverflow
        );

        const swiper = new Swiper(this, options);

        for (const e in events) {
          if (e) {
            swiper.on(e as any, (events as any)[e]);
          }
        }

        if (navigationElement) {
          $(navigationElement).on("click", ".nav-item", function (evt) {
            evt.preventDefault();

            const $item = $(this);
            let $activeItem = $item;

            if (navigationActiveSelector) {
              $activeItem = $(navigationActiveSelector, $item);
            }

            if ($activeItem.hasClass(navigationActiveClass)) {
              return false;
            }

            const index = $item.data("step") || $item.index() + 1;
            swiper.slideTo(index);

            if (navigationActiveSelector) {
              $item.siblings().each(function () {
                ($(navigationActiveSelector, this) as any).removeClass(
                  navigationActiveClass
                );
              });

              $activeItem.addClass(navigationActiveClass);
            } else {
              $item
                .siblings("." + navigationActiveClass)
                .removeClass(navigationActiveClass);
              $item.addClass(navigationActiveClass);
            }

            return false;
          });
        }
      });
    });
  }

  private actionClickWA() {
    window.open(
      `https://api.whatsapp.com/send?phone=${this.whatsapp_number.replace(
        "+",
        ""
      )}`
    );
  }
  private waHover() {
    const element = document.getElementById(
      "btn-wa-footer"
    ) as HTMLImageElement;
    element.setAttribute("src", this.locale === "en" ? waHoverEn : waHover);
  }
  private waNotHover() {
    const element = document.getElementById(
      "btn-wa-footer"
    ) as HTMLImageElement;
    element.setAttribute(
      "src",
      this.locale === "en" ? waNotHoverEn : waNotHover
    );
  }

  private navbarSticky() {
    const $navbar = $(".main-nav");
    const stickyPoint = 90;
    if (($(window).scrollTop() as number) >= stickyPoint) {
      $navbar.addClass("navbar-sticky");
    } else {
      $navbar.removeClass("navbar-sticky");
    }
  }
}
