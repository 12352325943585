import { AxiosResponse } from "axios";
import { BaseAdmin } from "./bases/base.service";

export default class AdminService {
  public getIndustry(params: any = {}): Promise<AxiosResponse<any>> {
    return BaseAdmin().get("general/industries", { params });
  }
  public getCountry(params: any = {}): Promise<AxiosResponse<any>> {
    return BaseAdmin().get("general/countries", { params });
  }
  public getProvince(params: any = {}): Promise<AxiosResponse<any>> {
    return BaseAdmin().get("general/provinces", { params });
  }
  public getCity(params: any = {}): Promise<AxiosResponse<any>> {
    return BaseAdmin().get("general/cities", { params });
  }
  public getDegree(params: any = {}): Promise<AxiosResponse<any>> {
    return BaseAdmin().get("general/degrees", { params });
  }
  public getMajor(params: any = {}): Promise<AxiosResponse<any>> {
    return BaseAdmin().get("general/majors", { params });
  }
  public getSchoolTypes(params: any = {}): Promise<AxiosResponse<any>> {
    return BaseAdmin().get("general/school-types", { params });
  }
  public getEventLanding(params: any = {}): Promise<AxiosResponse<any>> {
    return BaseAdmin().get("landing/get", { params });
  }
}
