























































































































































import filters from "@/filters";
import { Component, Vue } from "vue-property-decorator";
import { State } from "vuex-class";
import $ from "jquery";

@Component({
  filters,
  components: {},
  metaInfo: {
    meta: [
      {
        name: "title",
        content:
          "Wujudkan Mimpi Startup, Inspirasi Masa Depan: Career.Support - Inkubasi, Job Fair & CSR.",
      },
      {
        name: "description",
        content:
          "Wujudkan Startupmu, Raih Karir Cemerlang, dan Berdampak Positif: Career.Support menawarkan inkubasi, pameran kerja dan program CSR untuk masa depanmu.",
      },
      {
        name: "keywords",
        content:
          "Inkubasi Startup, Pameran Kerja, Program CSR, Ekosistem Kewirausahaan, Kegiatan Magang",
      },
    ],
  },
})
export default class EntrepreneurshipIncubation extends Vue {
  @State("locale")
  private locale!: string;

  private get items() {
    return Array.from({ length: 2 }, (_, i) => i + 1).map((value) => ({
      id: value,
      image: require(`@/assets/Images/entrepreneurship-incubation-item-${value}.svg`),
    }));
  }

  private scrollDown() {
    $("html, body").animate(
      {
        scrollTop: ($("#scroll-down") as any).offset().top - 90,
      },
      500
    );
  }
}
