








































































































































































































































































































































































import Vue from "vue";
import Component from "vue-class-component";
import { FOOTER_PAGES } from "@/constants/pages.constant";

@Component({})
export default class AppFooter extends Vue {
  private pages = FOOTER_PAGES.filter(
    (value) => value.name !== "footer_page_6"
  );

  private scrollTo(selector: string) {
    document.querySelector(".MainLanding")?.scrollTo({
      top:
        Number(document.querySelector<HTMLDivElement>(selector)?.offsetTop) -
        90,
      behavior: "smooth",
    });
  }
}
