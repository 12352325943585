import { Module } from "vuex";
import { RootState } from "../types";
import { CandidateState } from "./types";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";

export const state: CandidateState = {
  initial: null,
};

const namespaced: boolean = true;

export const candidate: Module<CandidateState, RootState> = {
  namespaced,
  state,
  actions,
  getters,
  mutations,
};
